














































import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import MainSlogan from "@/components/common/MainSlogan.vue"; // @ is an alias to /src
import WordOnPic from "@/components/common/WordOnPic.vue";
import AppArea from "@/components/common/AppArea.vue";

@Component({
  components: {
    Layout,
    MainSlogan,
    WordOnPic,
    AppArea,
  },
})
export default class SmartHome extends Vue {
  sfour: string = require("@/assets/storage/img/sfour.png");
  format(p: number) {
    return p / 10 + "x小时";
  }
}
